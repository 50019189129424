
@import "variables.less";


/*  
	------- Color Code --------- 
	Button Blue Color:   #2d5c8f;  Blue
	Button Green Color:  #9dbe54;  Green
	Button Gray Color:   #a5a5a2;  Gray

	Default Text Color: #6d6c6b;  Gray

	progressbar Bar Dark Gray Color:    #747471;  Gray - Dark
	progressbar Bar Light Gray Color:   #f9f8f6;  Gray - Light

	Accent Color : #b64f37;   Orange-Brown

	--- Panel ---
	Panel Background Gray:  #f9f8f5;   Light Gray
	Default Paragraph Gray: #444442;   Dark Gray

	   --   Verified   --
	   span Blue,
	   Panel-Heading Text:       #2b5a8c;   Dark Blue
	   Panel-Heading Background: #cedde5;   Light-Blue
	   Border-Gray:              #cbccc9;   Gray

	   -- Not Verified --
	   Warning-color,
	   Panel-Border:   #c73936;   Red
	   Panel-Heading : #fff;      White
	----------------------------
*/



/* ---------------------------------- *
 *                                    *
 *               F O R                *
 *      P R E S E N T A T I O N       *
 *           P U R P O S E            *
 *                                    *
 * ---------------------------------- */
body {
	font-family: 'Open Sans', sans-serif;
    color: @text-color;
}

body div.container>h3 {
	border-top: 1px dashed #ccc;
	padding-top: 20px;
}
body div.container>h3:first-of-type {
	border-top: 0;
}
h4 {
    font-weight: normal;
    color: #000000;
}
section {
	margin-bottom: 35px;
}
@media screen and (max-width: 767px) {
	section article {
		margin-top: 5px;
	}	
}

section.icon i {
	margin-right: 2em;
	margin-bottom: 1em;
	text-align: center;
}
section .sub-section {
	clear: both;
    border-top: 1px dotted;
    padding-top: 20px;
    margin-right: 15px;
    margin-left: 15px;
}
section .sub-section h5,
section .sub-section p {
	margin: 0;
}



.branding {
	margin-bottom: 150px;
	color: @gray-text;
	text-transform: uppercase;
}
.branding span {
	display: block;
}
.branding h1 {
	font-size: 5em;
}
.branding p {
	font-weight: 700;
}
.branding .color-accent {
	color: #b64f37;
}
.see-more {
	font-size: 16px;
	color: #b64f37;
	text-align: right;
	display: block;
	margin:0
}

/* Font */
section.font h5 {
   font-family: 'Open Sans', sans-serif;
   font-weight: 300;
   font-size: 1em;
}

/* Color */
section.icon i {
	color: #747471;

}
section.font h5 {
	color: @gray-text;
}




/* ---------------------------------- *
 *                                    *
 *             H E A D E R            *
 *          S I T E - I N F O         *
 *                                    *
 * ---------------------------------- */


body {
  	padding-top: 55px;
}

.navbar {
	    border-top: 5px solid;
}
.navbar .nav li {
	padding: 18px 0;
}
.navbar .nav li a {
	border-right: 1px solid;
    padding: 0 15px;
	line-height: 14px;
}
.navbar .nav li:last-child a {
	border-right: 0;
}
.business-name {		
	display: none;
}


.hero {
	margin-bottom: 40px;
	position: relative;
}
.hero img {
	min-width: 100%;
}
.hero-overlay-mask {
	background: rgba(55,127,146,.75);
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
}

/* Color */
.navbar-default .navbar-nav > li > a {
    color: #000;
}
.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: #000;
    background-color: inherit; /* #e7e7e7; */
    font-weight: 700;
}

/* mobile navbar */
.navbar-default .navbar-toggle {
    border-color: transparent;
}
.navbar .navbar-header .navbar-brand .business-name {
	color: #fff;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: transparent;
}

@media (max-width: 767px) {
	.navbar-default .signed-in .navbar-nav>li>a {
    	color: #fff;
	}
	.navbar-default .signed-in .navbar-nav>li>a:focus,
	.navbar-default .signed-in .navbar-nav>li>a:hover {
	    color: #dfdfdf;
	}
	.navbar-default .signed-in .navbar-nav>.active>a,
	.navbar-default .signed-in .navbar-nav>.active>a:focus,
	.navbar-default .signed-in .navbar-nav>.active>a:hover {
    	color: #fff;
	}
}


/* ---------------------------------- *
 *                                    *
 *             F O O T E R            *
 *          S I T E - I N F O         *
 *                                    *
 * ---------------------------------- */

/*
.site-info
	div.copyright
		p.company
		address.adress
		a.phone
	div.menu
		ul
			li
				a
*/


/* Copyright
   ---------------------------------- */
.site-info {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px 0;
    z-index: 200;
}
.site-info .copyright address {
	display: inline-block;
}
.site-info .copyright a[href^="tel:"]:link,
.site-info .copyright a[href^="tel:"]:visited,
.site-info .copyright a[href^="tel:"]:active,
.site-info .copyright a[href^="tel:"]:hover {
	text-decoration: none;
}


/* Menu
   ---------------------------------- */
.site-info .menu {
	text-align: right;
}
@media (max-width: 991px) {
	.site-info .menu {
		text-align: center;
	}
}
.site-info .menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
.site-info .menu ul li {
	display: inline-block;
	padding: 3px 0;
}
.site-info .menu ul li a {
	border-left: 1px solid;
	line-height: 14px;
	padding: 0 10px;
}
.site-info .menu ul li:first-child a {
	border-left: 0;
}
.site-info .menu ul li a:link,
.site-info .menu ul li a:visited,
.site-info .menu ul li a:active,
.site-info .menu ul li a:hover {
	text-decoration: underline;
}


/* Color
   ---------------------------------- */
.site-info {
	background: #444;
}
.site-info,
.site-info .copyright a[href^="tel:"]:link,
.site-info .copyright a[href^="tel:"]:visited,
.site-info .copyright a[href^="tel:"]:active,
.site-info .copyright a[href^="tel:"]:hover,
.site-info .menu ul li a:link,
.site-info .menu ul li a:visited,
.site-info .menu ul li a:active,
.site-info .menu ul li a:hover {
	color: #fafafa;
}
.site-info .copyright .company,
.site-info .standard,
.site-info .menu ul li:last-child a:link,
.site-info .menu ul li:last-child a:visited,
.site-info .menu ul li:last-child a:active,
.site-info .menu ul li:last-child a:hover {
	color: #eee;
}
.site-info .standard {
     display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
	display: -ms-flexbox;      /* TWEENER - IE 10 */
	display: -webkit-flex;     /* NEW - Chrome */
	display: flex; 
	align-items: center;
    justify-content: center;   /* NEW, Spec - Opera 12.1, Firefox 20+ */
	position:relative;
}
.site-info .standard p {margin:1em 0;}


/* ---------------------------------- *
 *                                    *
 *           B U T T O N S            *
 *                                    *
 * ---------------------------------- */


/* Default Classes
   ---------------------------------- */
.btn {
	white-space: normal;
	text-transform: uppercase;
    padding: 14px 16px;
}
.btn-square {
	border-radius: 0;
}
/* Primary Buttons
   ---------------------------------- */
.btn-primary {
	color: #fff;
    background-color: @branding-blue;
    border-color: @branding-blue;
    &:hover {
        color: black;
    }
}
.btn-primary-disabled {
    color: #fff;
    background-color: @gray;
    border-color: @gray;
}

.btn-primary-green {
    color: #fff;
    background: @branding-green;
    border: 1px solid @branding-green;

    &:hover {
        background: @branding-green-hover;
        color: #fff;
    }
}
.btn-primary-green[disabled] {
    color: #fff;
    background-color: @gray;
    border-color: @gray;
}

.btn-primary-blue {
    color: #fff;
    background: @branding-blue;
    border: 1px solid @branding-blue;

    &:hover {
        background: @branding-blue-hover;
        color: #fff;
    }
}

.btn-primary-blue[disabled] {
    color: #fff;
    background-color: @gray;
    border-color: @gray;
}

/* Secondary Buttons
   ---------------------------------- */
.btn-secondary {
	color: @branding-blue;
    background-color: #fff;
    border-color: @branding-blue;
}
.btn-secondary-green {
	color: @branding-green;
    background-color: #fff;
    border-color: @branding-green;
}
.btn-secondary-green-compliant {
    color: @compliance-color-green;
    background-color: #fff;
    border-color: @compliance-color-green;
}
.btn-secondary-disabled {
	color: @gray;
    background-color: #fff;
    border-color: @gray;
}
.btn-sign-in {
    height: 52px;
    border-radius: 9px;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    width:60%;
}
.btn-sign-in i {
    margin-right: 5px;
}

@media (max-width: 480px) {
    .btn-sign-in {
       width: 100%;
    }
}
.sign-in-div {
    display: flex;
    justify-content: center;
    align-content: center;
}
/* Other Types of Button
   ---------------------------------- */
.btn-type-b {
    color: @branding-blue;
    background-color: #fff;
    border-color: @gray;
    border-width: 1px 0;
    border-radius: 0;
}
.btn-arrow,
.btn-icon {
    text-align: left;
    position: relative;
}
.btn-arrow .position-right {
	position: absolute;
	top: 50%;
	margin-top: -7px;
	right: 5px;
}
.btn-icon {
	padding-left: 33px !important;
}
.btn-icon .position-left {
	position: absolute;
	top: 50%;
	margin-top: -7px;
	left: 13px;
}

a[disabled="disabled"], a[disabled] {
    cursor: not-allowed;
    color: @gray;
}

.action-btn-group .btn {
    margin-bottom: 1rem;
}

.btn-danger {
    background-color: @compliance-color-red;
}

/* Alert Boxes (Panels)
   ---------------------------------- */
.panel-alert,
.panel-alert .panel-heading {
   	border-radius: 0;
    border-color: @border-gray;
   }
.panel-alert .panel-heading .panel-title i.fa {
    color: #000 !important;         // to fix contrast in title of health insurance
    position: absolute;
    right: 25px;
}
.panel-alert .panel-body p {
    margin: 0;
}
.panel-alert .panel-body ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.panel-alert.panel-verified .panel-body,
.panel-alert.panel-alert-mute .panel-body {
    font-weight: 700;
}


.panel-alert.panel-not-verified .panel-body,
.panel-alert.panel-no-insurance .panel-body {
    padding: 30px 15px;
    text-align: center;
}

.panel-alert.panel-no-insurance .panel-title i.fa {
    position: initial;
    margin-left: 5px;
}
.panel-alert.panel-no-insurance .panel-body {
    font-weight: normal;
}

.panel-contact-us {
    border: 1px solid #82BC00;
    border-left: 30px solid #82BC00;
    border-radius: 0px;
    display: inline-block;
    span {
        font-size: 20px;
    }
}

/* |--- Color ---| */
.panel-alert {
	background-color: @light-gray;
}
.panel-alert .panel-body {
	color: @dark-gray;
}

/* Panel-Alert Verified */
.panel-alert.panel-verified>.panel-heading {
    color: @dark-blue;
    background-color: @light-blue;
}
.panel-alert.panel-verified .panel-body span.value {
    color: @dark-blue;
}

/* Panel-Alert NOT Verified */
.panel-alert.panel-not-verified {
    border-color: @warning-color;
}
.panel-alert.panel-not-verified>.panel-heading {
    color: #fff;
    background-color: @warning-color;
    border-color: @warning-color;
}
.panel-alert.panel-not-verified .panel-body i.fa,
.panel-alert.panel-no-insurance .panel-body i.fa  {
    color: @warning-color;
}

/* Panel-Alert Mute */
.panel-alert.panel-alert-mute>.panel-heading {
    color: #000;
    background-color: #b6b6b6;
    border-color: @border-gray;
}
.panel-alert.panel-alert-mute .panel-body {
    color: @dark-gray;
}
.panel-alert.panel-alert-mute .panel-body span.value {
    color: @dark-gray;
}

.panel .panel-body span.value {
    font-size: 18px;
}



/* ---------------------------------- *
 *                                    *
 *             O T H E R S            *
 *                                    *
 * ---------------------------------- */

.faq-item {
    font-size: 18px;
    font-weight: normal;
}
.faq-answer {
    font-size: 15.4px;
    color: @text-color;
    background-color: #ededed;
    padding: 5px;
    margin-bottom: 10px;
}
/* Bubble
   ---------------------------------- */
.bubble-box {
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    margin-bottom: 20px;
    border-color: #2d5c8f;
    position: relative;
}
.bubble-box:before{
	content: "";
    position: absolute;
    bottom: -15px;
    border-width: 15px 15px 0;
    border-style: solid;
    border-color: #2d5c8f transparent;
    display: block;
    width: 0;
}
.bubble-box:after {
    content: "";
    position: absolute;
    bottom: -13px;
    border-width: 14px 14px 0;
    border-style: solid;
    border-color: #fff transparent;
    display: block;
    width: 0;
}
.bubble-box.left-angle:before{
    left: 50px;
}
.bubble-box.left-angle:after {
    left: 51px;
}
.bubble-box.right-angle:before{
    right: 50px;
}
.bubble-box.right-angle:after {
    right: 51px;
}
.bubble-box .close-btn {
    position: absolute;
    top: 7px;
    right: 10px;
    color: #2d5c8f;
}
.bubble-box {	
	box-shadow: 0 0 4px #747471;
}


.bubble-title {
	margin-top: 0;
}
.bublbe-copy {}
.bubble-paragraph {}


/* -- Bubble-rounded -- */

.bubble-rounded {
	border-radius: 4px;
	text-align: center;
}
.bubble-rounded:before{
    left: 50%;
    margin-left: -22px;
}
.bubble-rounded:after {
    left: 51%;
    margin-left: -23px;
}
	@media (max-width: 767px) {
		.bubble-rounded:after {
		    margin-left: -27px;
		}
	}
	@media (max-width: 480px) {
		.bubble-rounded:after {
		    margin-left: -25px;
		}
	}
.bubble-rounded .bubble-copy .bubble-paragraph {
	margin: 10px 0;
}
/* Drop Down Menu
   ---------------------------------- */
.dropdown-toggle {
	width: 100%;
	text-align: left;
}
.dropdown-toggle.btn-default {
    background-image: none;
    background: none;
    border: none;
}
.open>.dropdown-menu {
    display: block;
    width: 100%;
    border-top: 2px solid;
    border-radius: 0;
    box-shadow: none;
    margin-top: 0;
}
.dropdown-menu>li>a {
    padding: 3px 12px;
}
.caret {
	vertical-align: middle;
    border-top: 7px dashed;
    border-top: 7px solid\9;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;

	position: absolute;
    right: 6px;
    top: 50%;
    margin-top: -4px;
}

.open>.dropdown-menu {
    border-top-color: #2d5c8f;
    background: #f9f8f6;
}
.dropdown-menu>li>a {
    color: #2d5c8f;
}
.dropdown-toggle.btn-default.btn-square,
.dropdown-toggle.btn-default.btn-square.focus,
.dropdown-toggle.btn-default.btn-square:focus,
.dropdown-toggle.btn-default.btn-square:hover {
    color: #2d5c8f;
    background-color: #fff;
    border-color: #fff;
}


/* Process Bar
   ---------------------------------- */

.progressbar li a {
	text-decoration: none;
}
.progressbar-box {
	display: table;
	width: 100%;
	margin-top: 20px;
	border: 1px solid;
}
.progressbar {
	padding-left: 0;
	margin-left: 0;
	display: table-row;
}
.progressbar li {
	display: table-cell;
	list-style: none;
	height: 30px;
	text-align: center;
	vertical-align: middle;
	position: relative;
}
.progressbar li:after {
	content: "";
	border-width: 15px 0 15px 15px;
	border-style: solid;
	position: absolute;
	right: -15px;
	top: 0;
}
.progressbar li:before {
	content: "";
	border-width: 15px 0 15px 15px;
	border-style: solid;

	position: absolute;
	left: -15px;
	top: 0;
}

.progressbar li:first-child:before {
	display: none;
}
.progressbar li:last-child:after {
	display: none;
}

.progressbar li:not(:first-child) {
    margin-left: 5px;
}

.progressbar-box {
	border-color: @gray; 
}
.progressbar li {	
    background-color: @light-gray;
}
.progressbar>li.active {
    color: #fff;
    background: @branding-blue;
}
.progressbar li:before {
	border-color: @light-gray transparent;
}
.progressbar li:after {
	border-color: transparent @light-gray;
}

.progressbar li.active:before {
    border-color: @branding-blue transparent;
}
.progressbar li.active:after {
    border-color: transparent @branding-blue;
}
.progressbar li a {
    color: #4a4a4a;
}
.progressbar li.active a {
    color: #fff;
}

/* "Radio button" style toggle */
.toggle-box {
    display: table;
    margin: 20px;
    border: none;
}
.toggle-box-list {
    display: table-row;
}
.toggle-box-list li {
    display: table-cell;
    list-style: none;
}

.toggle-box-list .toggle-item {
    display: inline-block;
    border: 1px solid @branding-blue;
    border-radius: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    left: 0;
    z-index: 5;
}

.toggle-box input[type=radio] {
    display: none;
}

.toggle-box-list label {
    width: auto;
    text-align: center;
}

.toggle-box input[type=radio]:checked+label {
    background: @branding-blue;
    color: white;
}

/* "Radio button" style toggle */
.paperless-box-green {
    display: table;
    margin: 20px;
    border: none;
}

.paperless-box ul {
    padding-inline-start: 0;
    display: inline-table;
}

.paperless-box-list li {
    display: table-cell;
    list-style: none;
    width: 45%;
}

.paperless-box-list .right-item {
    padding-left: 2%
}

.paperless-box-list .left-item {
    padding-right: 2%
}

.paperless-box-list .toggle-item {
    width: 100%;
    min-width: 115px;
    display: inline-block;
    border: 1px solid #6d6c6b;
    border-radius: 4px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
    left: 0;
    z-index: 5;
}

.paperless-box input[type=radio] {
    display: none;
}

.paperless-box-list label {
    width: 100%;
    text-align: center;
}

.paperless-box input[type=radio]:checked + label {
    background: @branding-blue;
    color: white;
}

/* Fonts
   ---------------------------------- */

/* Font Family */
.btn, .button,
.progressbar,
.body {
   font-family: 'Open Sans', sans-serif;
}

/* Font Weight */
.header-1,
.header-2,
.paragraph-1,
.paragraph-2,
.paragraph-4,
.button-1 {
	font-weight: bold;
}
.header-3 {
	font-weight: 300; /* Light 300 */
}
.header-4,
.header-5,
.paragraph-3,
.link-1,
.link-2 {
	font-weight: bold;
}
.link-3 {
    font-weight: 300;
}
.btn, .button,
.progressbar,
.button-2,
.button-3 {
	font-weight: 700; /* Bold 700 */
}

/* |-- Font Size -- Base Font Size: 14px --|*/

.btn:not([class*="ui-select"]):not(:empty):not([class*="ui-select"] .btn),
.progressbar {
	font-size: 1em; /* Should be 3em */
	padding:15px; /*to match comp*/
}

/*[class*="ui-select"] .btn {
    padding: inherit;
}*/

.header-1 {
	font-size: 4.3em;
}
.button-1 {
	font-size: 2.9em;
}
.header-2 {
	font-size: 24px;
}
.header-3,
.paragraph-1,
.button-2 {
	font-size: 1.7em;
}
.header-4,
.button-3 {
	font-size: 1.4em;
}
.paragraph-2 {
	font-size: 1.3em;
}
.header-5,
.paragraph-3,
.paragraph-4,
.link-1 {
	font-size: 1.1em;
}
.link-2 {
	font-size: 14px;
}
.link-3 {
    font-size: 0.8em;
}


/* Font Color */
.header-1,
.header-2,
.header-3,
.header-4,
.header-5,
.paragraph-1,
.paragraph-2,
.paragraph-3,
.paragraph-4,
.button-1,
.button-2,
.button-3,
.link-1,
.link-2,
.link-3 {
    color: @text-color;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 18px;
    top: 4px;
    margin: 0px 12px;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    background-color: #e6e6e6;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: -4px;
    top: -4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #bbb;
    border-radius: 50%;

}
input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
    background-color: #8dc63f;
}
.slider-selection {
    font-weight: 600;
}

/* ---------------------------------- *
 *                                    *
 *           Login Page               *
 *                                    *
 * ---------------------------------- */

.who-is-this {
	border-top:4px solid #444; 
	text-align:center;
	position:relative;
	z-index:100;
	margin-bottom:75px
}

.who-is-this a {
	display:inline-block;
	color:#fff;
	text-decoration:underline;
	font-size:12px;
	background:#444;
	padding:5px 20px;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.main-image-area:before{
    top:0px;
    content:"";
    width:100%;
    position:absolute;
    background-size:cover;
    background-position:center;
    background-color:grey;
    background-image:url("/Content/zotec/images/green_header.png");
}

@media (min-width: 768px) {
    .left {
        width: 50%;
        height: 100%;
        float:left;
        background-color:lightgrey;
    }
}

.login-container {
    position: relative;
    z-index: 100;
    padding: 40px;
}
.login {
	background:#fff;
	border:1px solid #cfcfcf;
	padding:20px 15px
}

.login-inline {
    float: left;
}
.login-input-separator {
    float: left;
    margin: 3px 6px;
    font-size: 16px;
    height: 20px;
    line-height: 19px;
}
.login-account-number {
    width: 100px;
}
.login-client-id {
    width: 75px;
}
.login-billing-group {
    width: 45px;
}
.date-portion input {
    text-align: center;
    height: 30px;
}
.date-two-digits {
    width: 35px;
}
.date-four-digits {
    width: 50px;
}
.account-portion input {
    height: 30px;
    padding-left: 5px;
}

.account-portion input:disabled {
    background-color: #D2D2D0;
}

.main-image-area:before {
    height: 23%;
}
.login-container {
        max-width: none;
        margin: 0 auto;
        padding: 0;
    }

.login {
    border: none;
    margin: 4%;
    margin-top: 19vh;
    padding: 20px 15px;
    text-align:center;
}

    .logo-row img {
        display: block;
        margin: 15px auto
    }

    .who-is-this {
        margin-bottom: 15px;
    }

    .extra-links {
        margin: 0;
    }

    .navbar-greeting {
        height: 50px;
        padding: 15px 0px;
        font-weight: bold;
        font-size: 14px;
    }

    .extra-space-30px {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .float-l {
        float: left;
    }

    .m-t-px-0 {
        margin-top: 0px;
    }

    .m-t-px-5 {
        margin-top: 5px;
    }

    .m-t-px-8 {
        margin-top: 8px;
    }

    .m-t-px-12 {
        margin-top: 12px;
    }

    .m-t-px-15 {
        margin-top: 15px;
    }

    .m-t-px-20 {
        margin-top: 20px;
    }

    .m-t-px-24 {
        margin-top: 24px;
    }

    .m-t-px-65 {
        margin-top: 65px;
    }

    .m-b-px-10 {
        margin-bottom: 10px;
    }

    .m-b-px-15 {
        margin-bottom: 15px;
    }

    .m-tb-px-5 {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .m-tb-px-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .p-t-px-24 {
        padding-top: 24px;
    }

    .p-lr-px-15 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .p-l-px-0 {
        padding-left: 0px;
    }

    .p-l-px-5 {
        padding-left: 5px;
    }

    .p-r-px-0 {
        padding-right: 0px;
    }

    .p-r-px-5 {
        padding-right: 5px;
    }

    .p-r-px-75 {
        padding-right: 75px;
    }

    .font-weight-section {
        font-weight: normal;
    }

    .font-size-section {
        font-size: 18px;
    }

    .dates-of-service {
        font-weight: normal;
    }

    .date-range-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: space-between;
    }

    .search-header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .charge-line-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 5px;
    }
    
    .charge-line-container button {
        margin-top: 10px;
    }

    .charge-line-container .ineligible-encounter {
        width: 100%;
        background-color: #e0e0e0;
        color: black;
        font-size: 1em;
        padding: 15px;
        font-weight: 700;
        text-align: center;
        border-radius: 5px;
    }

    .charge-line-divider {
        margin: 0px;
    }

    .greylink {
        color: #666;
        text-decoration: underline;
    }

    .greylink:focus {
        color: #666;
    }
    /* form and modal updates */
    /* Form elements */
    label,
    input:not([type="checkbox"]),
    textarea,
    select {
        display: block;
        width: 100%;
    }

    label {
        font-weight: 400;
        font-size: 16px;
    }

    .input-label {
        color: #717170;
        font-size: 10px;
    }

    .input-standard {
        background: none;
        font-weight: 700;
        border: none;
        padding: 10px;
        border-bottom: 1px solid @gray;

        &:focus {
            border-bottom: 1px solid @branding-blue;
        }
    }

    .input-wrap {
        font-weight: 700;
        color: @branding-blue;
        font-size: 18px;
        display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
        display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
        display: -ms-flexbox; /* TWEENER - IE 10 */
        display: -webkit-flex; /* NEW - Chrome */
        display: flex;
        align-items: center;
        justify-content: center; /* NEW, Spec - Opera 12.1, Firefox 20+ */
        position: relative;
    }
    /*.input-wrap i {
	padding:0 0 0 5px;
}*/

    .input-container {
        padding: 20px 5px;
    }
    /* Input Select */
    .bootstrap-select .btn {
        border: none;
        font-weight: 700;
        color: #2d5c8f;
        font-size: 18px;
        border-radius: 0;
        -webkit-box-shadow: none;
        white-space: nowrap;
    }


    .bootstrap-select .btn-default:hover {
        background-color: #e6e6e6;
    }

    .bootstrap-select .dropdown-menu {
        padding: 0;
        font-size: 18px;
        border: 1px solid @gray;
        border-radius: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .bootstrap-select .dropdown-menu li a {
        border-top: 1px solid @gray;
        background: #f1f2f3;
        color: #2d5c8f;
    }


    .bootstrap-select .dropdown-menu li a:focus {
        outline: none;
        color: #2d5c8f;
        background: #bbccde
    }
    /* checkbox */
    .input-container .checkbox label {
        padding-left: 10px;
    }

    .input-container .checkbox label::before {
        width: 25px;
        height: 25px;
        top: -1px;
        margin-left: -20px;
        border: 1px solid #2d5c8f;
    }


    .input-container .checkbox-primary input[type="checkbox"]:checked + label::before {
        background-color: #ffffff;
        border-color: #2d5c8f;
    }

    .input-container .checkbox-primary input[type="checkbox"]:checked + label::after {
        color: #2d5c8f;
        font-size: 19px;
        top: -3px;
    }
    /* slider Range */
    .slider-container {
        padding: 25px 0;
        min-height: 100px;
    }
    /* ui-select */
    .input-container .ui-select-bootstrap {
        .text-muted {
            color: #757575 !important;
            font-weight: 700;
        }

        .ui-select-search {
            font-weight: 700;
            color: @branding-blue;
            font-size: 16px;
        }

        .ui-select-match-text {
            margin-top: -2px;
        }

        .ui-select-match {
            .btn-default {
                font-weight: 700;
                color: @branding-blue;
                font-size: 16px;
            }
        }

        ul {
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

            .ui-select-choices-row-inner {
                color: #2d5c8f;
            }
        }

        .btn-default {
            border-color: #a5a5a2;
        }

        .btn-link {
            margin-right: 0 !important;
        }

        .btn-xs {
            padding: 3px 0px 1px 5px;
        }

        .caret {
            display: none;
        }
    }
    /* Popup Tooltips */
    /* some are in the theme css */
    .popover-title {
        font-weight: 700;
        font-size: 18px;
        background-color: #fff;
        border-bottom: none;
    }

    .popover > .arrow:after {
        border-width: 8px;
    }

    .popover.top > .arrow:after {
        bottom: 3px;
        margin-left: -8px;
    }
    /* modal globals */
    /*removed BHK*/
    /*.modal button {
	margin:15px 0;
}*/
    /*single Modal alert*/
    .vertical-alignment-helper {
        display: table;
        height: 100%;
        width: 100%;
        pointer-events: none; /* This makes sure that we can still click outside of the modal to close it */
    }

    .vertical-align-center {
        /* To center vertically */
        display: table-cell;
        vertical-align: middle;
        pointer-events: none;
    }

    .modal-content {
        /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
        width: inherit;
        height: inherit;
        /* To center horizontally */
        margin: 0 auto;
        pointer-events: all;
    }

    .modal-content {
        border-radius: 0;
        border-top: none;
    }
    /* Grey bar Modal */
    .greybar .modal-content {
        position: relative;
    }

    .greybar .modal-content:before {
        content: "";
        display: block;
        height: 80px;
        width: 100%;
        background: #B1B1B1;
        top: 0;
        left: 0;
        position: absolute;
    }

    .modal-image {
        padding: 15px 0;
        display: block;
        margin: 0 auto;
    }
    /*alert modal*/

    .alertmodal .modal-content {
        border-radius: 5px;
        border: 1px solid;
        text-align: center;
        max-width: 400px;
    }
    /* Help Modal*/

    .modal h1, .modal h2, .modal h3, .modal h4, .modal p {
        margin: 0 0 30px 0;
    }

    .closemodal {
        font-size: 24px;
        color: #2d5c8f;
        padding: 0 10px;
    }

    .overlaymodal img {
        display: block;
        margin: 0 auto;
    }

    .overlaymodal {
        color: #fff;
        text-align: center;
    }

    .overlaymodal .modal-content {
        border: none;
        background: none;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .overlaymodal h1, .overlaymodal h2, .overlaymodal h3, .overlaymodal h4, .overlaymodal p {
        margin: 40px 0;
    }

    .closeoverlay {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        margin-top: 50px;
        display: inline-block
    }

    .closeoverlay:hover {
        color: #fff;
        text-decoration: underline;
    }
    /* Buttons */
    button {
        width: 100%;
    }
    /* accordion  - Faq elements */
    .accordion-group {
        border-bottom: 1px solid @gray;
    }

    .accordion-group:first-child {
        border-top: 1px solid @gray;
    }

    .accordion-toggle {
        display: block;
        padding: 15px 40px 15px 15px;
        color: #2d5c8f;
        position: relative;
    }

    .accordion-toggle i {
        float: right;
        margin: 6px -35px 0 0;
    }

    .collapsed .fa-chevron-down:before {
        content: "\f054" !important;
    }

    .accordion-inner .accordion-group {
        border: none;
        margin: 5px;
    }


    .single-answer {
        padding: 20px;
        background: @light-gray;
    }

    .input-standard.carrier-input.ng-valid.error:not(:focus) {
        border-bottom: 1px solid red;
    }

    .input-standard.carrier-input.error {
        color: @compliance-color-red;
    }

    div.carrier-not-found > label.we-cannot-find {
        color: @color-dark-red;
        font-size: 14px;
    }

    div.carrier-not-found > label.telephone {
        font-weight: 600;
    }
    /* ---------------------------------- *
 *                                    *
 *            General Color           *
 *           And Font overides        *
 *                                    *
 * ---------------------------------- */

    .font-weight-300 {
        font-weight: 300;
    }

    .font-weight-400 {
        font-weight: 400;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .font-weight-700 {
        font-weight: 700;
    }

    .color-blue {
        color: @branding-blue
    }

    .color-green {
        color: @branding-green     
    }

    .color-gray {
        color: @gray
    }

    .color-dark-gray {
        color: @dark-gray
    }

    .color-light-gray {
        color: @light-gray
    }

    .color-red {
        color: @compliance-color-red
    }

    .color-white {
        color: white
    }

    .color-black {
        color: black
    }
    .color-compliant-blue {
        color: #00476b
    }
    .color-compliant-green {
        color: @compliance-color-green
    }
    .center{
        text-align:center;
    }

    datepicker select, datepicker select:focus, datepicker select:hover,
    .datepicker select, .datepicker select:focus, .datepicker select:hover,
    [datepicker] select, [datepicker] select:focus, [datepicker] select:hover {
        background-color: #005da6 !important;
    }

    .center-account-number-forms {
        display: flex;
        justify-content: center;
    }
    .center-birthday-forms {
        display: flex;
        justify-content: center;
    }

    .login-border {
        border-color: rgba(0, 0, 0, 0.42);
        border-width: 1px;
    }
    .login-title{
        color:black;
    }

    .login {
        input {
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid rgba(0, 0, 0, 0.42);
        }
    }

    .h4-login {
        font-size: 17px;
        font-weight: 700;
    }

/* Google reCAPTCHA
   ---------------------------------- */
.g-recaptcha-accountLookup {
    transform: scale(0.70);
    transform-origin: 0 0;
}
.g-recaptcha-login > div {
    margin: 0 auto;
    transform: scale(0.75);
    display: inline-block;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.panel-heading {
    border: 3px;
    border-color: #2d5c8f;
    border-style: solid;
    float:left;
    width: 100%
}
