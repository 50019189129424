.contact-us-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 30px 0 20px 0;
    align-items: center;
    border-bottom: 1px solid black;
    max-width: 1000px;
}

.contact-us-header h1 {
    color: #000000;
}

.contact-us-header img {
    width: 150px;
}

.contact-us-message,
.form-section {
    max-width: 800px;
}

.contact-us-message {
    padding: 20px 0 0 0;
    font-weight: 400;
    color: #000000;
    font-size: 24px;
}

.contact-us-message a {
    color: inherit;
}

.form-section {
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    min-height: 70vh;
}

.form-section-disabled {
    opacity: 0.38;
}

.help-modal {
    display: block;
    margin-right: 50px;
    margin-left: 50px;
}

.login-message {
    padding: 40px 40px 0 40px;
    font-size: 18px;
    line-height: 28px;
    color: #0D3861;
}

.form-header {
    margin: 40px 40px 0 40px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    color: #0D3861;
    font-weight: 700;
}

.contact-us-form {
    display: flex;
    flex-direction: column;
    padding: 20px 70px 20px 40px;
    row-gap: 25px;
    font-family: sans-serif;
}

.contact-us-input label {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.54);
}

.contact-us-input input, .contact-us-input select, .contact-us-input textarea {
    height: 46px;
    border-radius: 4px;
    border: 1px solid black;
    font-size: 14px;
    font-weight: 400;
}

.contact-us-input input, .contact-us-input textarea {
    padding-left: 5px;
}

.contact-us-input label:after {
    color: #E91B11;
    content: '*';
    display: inline;
}

.patient-name select, .patient-name input {
    width: 70%;
}

.billing-topic select {
    width: 60%;
}

.contact-us-form .btn-primary-green {
    width: 113px;
    box-shadow: 4px 2px 5px 1px rgba(0, 0, 0, 0.2);
    font-size: 18px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.contact-us-form .btn-primary-green:disabled {
    background: #8DC63F;
    opacity: 0.38;
    color: #000000;
}

.contact-us-message textarea {
    height: 120px;
    padding-top: 8px;
    resize: none;
}

.contact-us-thankyou-message {
    margin: 40px 40px 0 40px;
    display: flex;
    column-gap: 10px;
}

.contact-us-thankyou-right-col {
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}

.contact-us-thankyou-green-check {
    height: 80px;
}

.contact-us-thankyou-home-button {
    width: auto;
    margin-left: auto;
    margin-right: 40px;
}

.text-count {
    float: right;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
}

@media (max-width: 991px) {
    .header-section {
       width: 90%;
        margin: 0 auto;
        max-width: 800px;
    }
    .form-section {
        margin: 0 auto;
        width: 90%;
    }
    .patient-name select,
    .patient-name input,
    .billing-topic select {
        width: 100%;
    }
}

@media (max-width: 590px) {
    .contact-us-header {
        flex-direction: column-reverse;
        align-items: self-start;
        padding-bottom: 0;
    }
}
