﻿.encounter-summary {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    border-radius: 5px;
    max-height: 250px;
    overflow: auto;
}

.encounter-summary-heading {
    background-color: #82BC00 !important;
}

.encounter-summary-heading > h3 {
    color: #ffffff;
    text-align: center;
    font-size: 20px;
}

.applicable-encounters-text {
    display: block;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 25px;
}

table.related-encounters-table {
    border: 1px solid #cbccc9;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    width: 60%;
}

.related-encounters-table thead {
    background-color: #f5f5f5;
    color: #707070;
    font-size: 14pt;
}

.related-encounters-table th {
    padding: 10px;
}

.related-encounters-table tr {
    border-bottom: 1px solid #cbccc9;
}

.related-encounters-table td {
    padding: 10px;
}