﻿
/* Apple Pay */
.additional-pay-button-hidden {
    display: none !important;
}

.apple-pay-container {
    min-height: 45px;
    max-height: 250px;
}

@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
    }

    .apple-pay-button-black {
        -apple-pay-button-style: black;
    }

    .apple-pay-button-white {
        -apple-pay-button-style: white;
    }

    .apple-pay-button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display: inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
        cursor: pointer;
    }

    .apple-pay-button-black {
        background-image: url("/Content/zotec/images/cards/digital-wallet-icon-apple-pay-black.png");
        background-position: center;
        background-size: 55px 40px;
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }

    .apple-pay-button-white {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }

    .apple-pay-button-white-with-line {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
        border: .5px solid black;
    }
}
