﻿.modal-container p, .modal-container h2, .modal-container h4 {
    margin: 0;
}

.modal-container {
    --primary-color: #215AA7;
    --secondary-color: #444444;
    --teritiary-color: #C4C4C4;
    display: flex;
    justify-content: space-around;
}

.col {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 12px;
}

.text-primary-blue {
    color: var(--primary-color);
}

.modal-close {
    opacity: .8;
}

.modal-close:hover {
    opacity: 1;
}

.secure-code-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.communication-preference select {
    background: url("../../images/icons/dropdown-polygon.png") no-repeat scroll 0 0 transparent;
    background-position: right center;
    background-size: 20px;
    border-color: var(--primary-color);
    color: var(--primary-color);
    border-radius: 3px;
}

.communication-preference select:focus-visible, .communication-preference input:focus-visible {
    outline: none;
}


.communication-preference input {
    border: none;
    border-bottom: 3px solid var(--teritiary-color);
    border-radius: 0px;
}

.btn-primary-green, .btn-primary-green:hover {
    color: #000000;
}

.sub-header {
    font-size: 15px;
    font-weight: 700;
}

.verification-code-input {
    font-size: 24px;
    text-align: center;
    letter-spacing: 3px;
    border: 1px solid var(--secondary-color);
}

.red-border {
    border: 1px solid red;
}

.link-bolded {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-weight: 700;
}

.verification-code-label {
    font-size: 15px;
}

.modal-content.no-border {
    border: none;
}

.center-text {
    text-align: center;
}

.col .text-align-left {
    margin-left: 18px;
}

.modal-container .text-error {
    color: #E91B11;
}

@media (max-width: 480px) {
    .modal-content.no-border.accountlookup {
        width: 90%;
    }

    .modal-container {
        flex-direction: column;
    }

    .col .text-align-left {
        margin-left: 0px;
    }

    .modal-logo {
        width: 60px;
    }
    .center-text {
        font-size: 22px;
    }

    .sub-header-h4 {
        width: 100%;
        font-size: 18px;
    }
}