﻿@import "variables.less";

.foundations.pplan-options {
    padding-top: 0;
    ul li {
        padding: 5px 0;
        border-bottom: none;

        input[type=radio] {
            top: 15px;
        }

        label {
            font-size: 18px;
            font-weight: normal;            
            b {
                font-weight: 900;
            }
            span{
                font-size: 14px;
            }
        }

        .pplan-option-amount-text{
            font-family: 'Open Sans', sans-serif;
        }

        .check {
            top: 10px;
        }
    }
}

.ppText {
    margin-top: 15px;
}

.ppTitleText {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
}

.greenText {
    color: @compliance-color-green;
    font-weight: 700;
    font-size: 20px;
}
.summaryText{
    font-size: 22px;
}

.pplan-edit-terms {
    margin-top: -15px;
}

