﻿
/*Author: Brendan Kirchner*/

@import "variables.less";

/*.ng-enter {
    opacity: 0;
}
.ng-enter-active {
    opacity: 1;
}
.ng-leave {
    transition: 0.0s;
    opacity: 1;
}
.ng-leave-active {
    opacity: 0;
}
*/

.main-window {
    min-height: 800px;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    display: flex;
    &:before {
        content:"";
	    width:100%;
	    height:100%;
	    position:absolute;
        left: 0;
        top: 0;
	    background-size:cover;
	    background-position:center;
    }
}

.patient-experience-view {
    padding-bottom: 60px;
}

a {
    cursor: pointer;
}

footer {
    width: 100%;
    a:hover {
        color: @gray;
    }
}

input[type="text"], input[type="number"], input[type="date"] {
    border-radius:8px;
}
i.fa-calendar {
    padding-right:5px;
}

/* for firefox red box around date picker */
input:required {
    box-shadow:none;
}
input:invalid {
    box-shadow:none;
}
/* for IE11 red box around input */
input:required:invalid {
    outline: none;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.underline {
    text-decoration: underline;
}

.overlaymodal {
    opacity: 1;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
}

.text-error {
    font-weight: 600;
    font-size: 14px;
    color: @compliance-color-red;
}

.text-bold {
    font-weight: bold;
}

.text-italic {
    font-style: italic;
}

.text-underline {
    text-decoration: underline;
}

/*.input-standard.has-error:not(:focus) {
    border-bottom: 2px solid #ff0000;
}*/
.input-standard.ng-invalid:not(:focus), .btn-select.ng-invalid:not(:focus) {
    border-bottom: 1px solid #ff0000;
}
.input-standard[disabled] {
    color: @dark-gray;
}
::-webkit-input-placeholder {
    color: @gray;
}
::-moz-placeholder {
    color: @gray;
}
:-ms-input-placeholder {
    color: @gray;
}
:-moz-placeholder {
    color: @gray;
}
.checkbox.ng-invalid {
    border-left: 1px solid #ff0000;
}

.popover-btn {
    cursor: pointer;
}

.popover-title {
    color: black;
}

.row-regular {
    margin: 0;
}

//am-fade animation
.am-fade-custom.ng-enter { 
    transition: 0.5s linear all;
    opacity: 0;
}
.am-fade-custom.ng-enter.ng-enter-active {
    opacity: 1;
}
.am-fade-custom.ng-leave {
  transition:0.5s linear all;
  opacity:1;
}
.am-fade-custom.ng-leave.ng-leave-active {
  opacity:0;
}

.splash-container {
	max-width:500px;
	position:relative;
	z-index:100;
	padding:40px 0;
}

.dot {
    margin: 0px 5px;
    display: inline-block;
    width:12px;height:12px;
    border-radius:6px;
    background-color:#8999A6;
}

.dot.selected {
    background-color: @branding-blue;
}

.dimmed:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.55);
}

.content-container {
	max-width:500px;
	position:relative;
	z-index:100;
	padding:40px 0;
}

.content {
    margin-top: 75px;
	background:#fff;
	border-bottom: 1px solid #CFCFCF;
	padding:10px 40px 20px 40px;
    button {
        margin: 15px 0;
    }
}

.img-top-container {
    max-width: none;
    max-width: none;
    width: 100%;
    padding: 0 200px 20px 200px;
    .content {
        margin-top: 0px;
        border-top: none;
        border-bottom: none;
    }
}

.home-content-container {
    max-width: 700px;
    width: 100%;
    .content {
        margin-top: 0px;
        border-top: none;
        border-bottom: none;
    }
}

.e-billing-notification-container {
    padding: 0 15px 20px;
}

.e-billing-notification {
    width: 100%;
    background: #82BC00;
    padding: 15px !important;
    border-radius: 0 !important;
    border: none;
}

.e-billing-notification .padded-left {
    padding-left: 15px;
}

.e-billing-notification img {
    display: inherit;
    width: 20px;
}

.e-billing-notification p {
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
    margin: 0;
    padding-right: 15px;
}

.e-billing-notification-content {
    display: table;
    width: 100%;
}

.e-billing-notification-content .leaf {
    display: table-cell;
    vertical-align: middle;
    width: 20px;
    padding-right: 15px;
}

.e-billing-notification-content-btn {
    display: table-cell;
    vertical-align: middle;
    width: 105px;
}

.e-billing-notification-content-btn-text {
    width: 105px;
    float: right;
    line-height: 33px;
    text-align: center;
    color: #000;
    font-size: 12px;
    font-weight: 500;
    border: 1.5px solid #fff;
}

.feedback-input-container {
    margin-bottom: 20px;

    textarea {
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        min-height: 200px;
        border: 1px solid @gray;
    }

    input {
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        padding: 2px;
        border: 1px solid @gray;
        max-width: 260px;
    }

    input.input-error {
        border-color: @warning-color;
    }

    .input-wrap {
        border: none;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .feedback-input-separator {
        float: left;
        margin: 3px 6px;
        font-size: 16px;
        height: 20px;
        line-height: 19px;
    }

    .feedback-account-number {
        float: left;
        width: 100px;
    }

    .feedback-client-id {
        float: left;
        width: 75px;
    }

    .feedback-billing-group {
        float: left;
        width: 45px;
    }

    .error-text {
        font-size: 12px;
        color: @warning-color;
    }
}

.insurance-content {
    padding: 0 40px;
}

.content-header {
    margin: 0px;
    padding: 20px 15px;
    
}
.content-sub-header {
    margin: 0px;
    padding: 0px 15px;
    margin-bottom: 5px;
}

.btn {
    text-transform: none;
}

.btn-select {
    text-align: left;
    border: none;
    background: none;
    border-bottom: 1px solid @gray;
    padding-top: 10px;
    padding-bottom: 10px;
    &:hover {
        background-color: @light-gray;
        border-bottom: 1px solid @branding-blue;
    }
}

.card-type-radio {
    display: inline-block;
    width: auto;
    padding: 0 5px 0 5px;
}

.card-type-image {
    opacity: 0.35;
    margin: auto;
}

.card-type-image.selected {
    opacity: 1;
}

.card-type-radio:checked + .card-type-image {
    opacity: 1;
}

.denomination {
    display: inline-block;
    width: auto;
}

.login-footer {
    vertical-align: middle;
    background-color: @light-gray;
    color: @dark-gray;
    padding: 10px 0 10px 0;
}

/*label {
    font-size: 20px;
    font-weight: 600;
}

input {
    font-size: 20px;
    font-weight: 600;
}*/

/*.panel-insurance-details {
    color: inherit;
}*/

/*insurance detail varification*/
.panel-insurance-details, #section-new-insurance, #section-communication {
    .row {
        padding-bottom: 20px;
    }
}


.logo-row img {
	display:block;
	margin:15px 0px;
}

.progressbar-box {
    margin-top: 0;
}

/*for the angular strap datepicker*/

.datepicker.dropdown-menu {
    width: 250px;
    height: 250px;
    button {
        outline: none;
        border: 0px;
        padding: 2px 0 2px 0;
    }
    button:not(:disabled) {
        color: @branding-blue;
    }
    tbody {
        height: 180px;
    }
    tbody button {
        padding: 6px;
    }
    tbody button.btn-primary {
        color: white;
    }
    &.datepicker-mode-1, &.datepicker-mode-2 {
        tbody button {
            height: 65px;
        }
    }
}

.timepicker.dropdown-menu {
    padding: 0 4px;
    button {
        outline: none;
        border: 0px;
    }
    button:not(:disabled) {
        color: @branding-blue;
    }
    tbody button {
        padding: 6px;
    }
}

ul.nav {
    margin-right: 0;
}

/*Below are sections for responsive sizing*/
@media (min-width: 1000px) { 
    .navbar .collapse { 
        display: block !important; 
    } 
}
@media (max-width: 999px) {

    .navbar-toggle {
        display: block;
    }
    .navbar-header {
        float: inherit;
    }

    .navbar .nav li {
		padding: 0;
	}
	.navbar .nav li a {
		border-right: 0;
    	padding: 15px;
		line-height: 20px;
	}
	.navbar .signed-in .navbar-header .navbar-brand img {
    	display: none!important;
	}
	.navbar-toggle {
    	margin-bottom: 7px; /* Original 8px */
		width:auto;
	}
	.navbar-default .navbar-toggle .icon-bar {
    	height: 4px; /* Original 2px */
    	border-radius: 0px; /* Original 1px */
        background: @branding-blue;
	}
	.navbar-toggle .icon-bar+.icon-bar {
    	margin-top: 2px; /* Original 4px */
	}
    ul.nav {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    /*top bar menu for mobile*/
    .navbar-collapse.in {
        ul.navbar-nav {
            li {
                width: 100%;
                text-align: center;
                color: @branding-blue;
                font-size: 20px;
                border-top: 1px solid @gray;
                margin: 0 10px 0 10px;
                /*&:first-of-type {
                    margin-top: 100px;
                }*/
                &:last-of-type {
                    /*margin-bottom: 100px;*/
                    border-bottom: 1px solid @gray;
                }
                a {
                    color: @branding-blue;
                }
            }
        }
    }
}

.img-responsive-100 {
	width: 100%;
	height: auto;
}

.navbar-brand {
    color: @navbar-compliant-gray !important;
    padding: 10px 15px;
}

.charge-group-block-inner {
    border-left: 2px solid @gray;
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.charge-block-first-patient {
    padding-top: 2px;
    padding-bottom: 10px;
}

.charge-block {
    padding-top: 10px;
    padding-bottom: 10px;
}

.charge-block-gray {
    background-color: @light-gray;
}

.charge-block-inner {
    border-left: 2px solid @gray;
    padding-left: 10px;
}

.charge-block-inner-denied {
    border-left-color: red;
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
    max-height: none;
}

.rating-image {
    opacity: 0.5;
    margin-left: auto;
    margin-right: auto;
}
.rating-image:focus {
    outline:0;
}
.rating-active {
    .rating-image {
        opacity: 1;
    }
}
.rating-image.active {
    opacity: 1;
}

.btn-type-b+.btn-type-b {
    border-top: none;
}

.input-client-id {
    text-transform: uppercase;
}

.guar-pat-box {
    display: inline-block;
	width: 100%;
}
.guar-pat-block {
    cursor: pointer;
    border: 1px solid @gray;
    background-color: @light-gray;
    color: @dark-gray;
    text-align: center;
    padding: 5px;
}
.guar-pat-block.active {
    background-color: @branding-blue;
    color: white;
}

.service-info-paragraph {
    margin-bottom: 5px !important;
    word-wrap: break-word;
}
.service-info-detail {
    margin-bottom: 0px;
}
.service-info-label {
    margin-top: -4px;
}
.service-info-ineligible {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}
.service-info-ineligible-icon {
    color: @branding-blue;
    font-size: 46px;
}
.service-info-ineligible-text {
    font-weight: 600;
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
    color: @branding-blue;
}

.popover-content {
    color: #303030;
}

.trouble-header {
    font-size: 21px;
}

.trouble-header-link {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

@media (max-width: 767px) {
    .main-window {
	    min-height:400px;
    }
}

@media (max-width: 991px) {
    .content-container {
        padding-left: 0;
        padding-right: 0;
    }
    .img-top-container {
        padding: 0 0 20px 0;
    }
    .home-content-container {
        padding: 0 0 20px 0;
    }
    .content {
        padding: 0px 20px 20px 20px;
        /*width: 100%;*/
        margin: 0 0;
    }
    .padded-content {
        margin: 0 5px;
    }
    .input-container {
        padding: 15px 5px;
    }
    .progressbar-box,.guar-pat-block {
        margin-top: 0px;
        border-left: none;
        border-right: none;
        border-top: none;
    }
    .panel-insurance-details, #section-new-insurance, #section-communication {
        .row {
            .col-md-6:last-of-type:not(:only-of-type) {
                    padding-top: 20px;
                }
        }
    }
}

@media all and (max-width: 475px) {
    .e-billing-notification-container {
        padding: 0 0 20px;
    }
    .e-billing-notification img {
        display: block;
        margin: 0 auto;
        width: 20px;
    }
    .e-billing-notification p {
        font-size: 16px;
        text-align: center;
        display: block;
        margin: 0 auto 16px;
        padding-right: 0;
    }
    .e-billing-notification-content {
        display: block;
    }
    .e-billing-notification-content .leaf {
        display: block;
        padding: 0;
        margin-bottom: 10px;
        width: 100%;
    }
    .e-billing-notification-content-btn {
        display: block;
        width: 100%;
    }
    .e-billing-notification-content-btn-text {
        display: block;
        margin: 15px auto 0;
        float: none;
    }
}

//the angular material slider
._md-track-container {
    background-color: @gray;
}
._md-thumb, ._md-focus-thumb, ._md-focus-ring {
    background-color: @branding-blue;
}

//webkit spinners
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

body ._pendo-badge_ {
    z-index: 1;
}

.video-player {
    width: 640px;
    height: 480px;
    align: middle;
    display: block;

    @media (max-width: 640px) {
        width: 100vw;
        height: 75vw;
    }
}

.feedback-survey {
    width: 100%;
    border: none;
}

.account-history-first-patient {
    margin-top : 30px;
}

.account-history-agency-warning {
    display: block;
    padding: 10px 5px;
    background-color: #e91c10;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
}

.holder {
    display: flex;
    align-items: center;
    border: 2px solid #005DA6;
    margin-top: 20px;
    margin-bottom: 20px;
}

.balance-not-found {
    padding-top: 15px;
    font-weight: 600;
}

.warning-panel {
    box-sizing: border-box;
    border-right: 2px solid #005DA6;
}

.warning-message {
    padding-left: 5px;
    font-size: 14px;
    font-weight: 600;
}

.h4-payment-pending {
    font-size: 14px;
    color: #000000;
}

.external-payment-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    row-gap: 20px;

    i {
        font-size: 60px;
    }
    h2 {
        font-weight: 700;
    }
    p {
        font-size: 18px;
        text-align: center;
    }
}

.cancel-button {
    padding: 0;
    border: none;
    background: none;
    color: rgba(0, 113, 206, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    width: fit-content;
    display: block;
    margin: auto;
}