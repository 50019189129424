#snapins_invite {
    background-color: #FFFFFF;
    font-family: "Arial", sans-serif;
    overflow: visible;            
    border-radius: 8px;
    visibility: hidden;
}

.embeddedServiceInvitation {             
    background-color: transparent;
    max-width: 290px;
    max-height: 210px;
    -webkit-box-shadow: 0 7px 12px rgba(0,0,0,0.28);
    -moz-box-shadow: 0 7px 12px rgba(0,0,0,0.28);
    box-shadow: 0 7px 12px rgba(0,0,0,0.28);

    @media only screen and (min-width: 48em) { /*mobile*/
        & {
            max-width: 332px;
            max-height: 410px;
        }
    }


    > .embeddedServiceInvitationHeader {
        width: inherit;
        height: 50px;
        line-height: 32px;
        padding: 10px;
        color: #FFFFFF;
        background-color: #005DA6;
        overflow: initial;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }
}

.embeddedServiceInvitationHeader {
    #embeddedServiceAvatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .embeddedServiceTitleText {
        font-size: 18px;
        color: #FFFFFF;
        overflow: hidden;
        word-wrap: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        align-self: stretch;
        flex-grow: 1;
        max-width: 100%;
        margin: 0 12px;
    }

    .embeddedServiceCloseIcon {
        border: none;
        border-radius: 3px;
        cursor: pointer;
        position: relative;
        bottom: 3%;
        background-color: transparent;
        width: 32px;
        height: 32px;
        font-size: 23px;
        color: #FFFFFF;
        &:focus {
            outline: none;
            &::before {
                content: " ";
                position: absolute;
                top: 11%;
                left: 7%;
                width: 85%;
                height: 85%;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 4px;
                pointer-events: none;            
            }
        }

        &:active, &:hover {
            background-color: #FFFFFF;
            color: rgba(0,0,0,0.7);
            opacity: 0.7;
        }        
    }
}


.embeddedServiceInvitation {
    > .embeddedServiceInvitationBody {
        background-color: #FFFFFF;
        max-height: 110px;
        min-width: 260px;
        margin: 0 8px;
        font-size: 14px;
        line-height: 20px;
        overflow: auto;
    }
    > .embeddedServiceInvitationFooter {
        width: inherit;
        color: #FFFFFF;
        text-align: right;
        background-color: #FFFFFF;
        padding: 10px;
        max-height: 50px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
}

.embeddedServiceInvitationBody p {
    color: #005DA6;
    padding: 8px;
    margin: 12px 0;
}


.embeddedServiceInvitationFooter {
     > .embeddedServiceActionButton {
        font-size: 14px;
        max-height: 40px;
        border: none;
        border-radius: 4px;
        padding: 10px;
        margin: 4px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        cursor: pointer;
    }

    > #acceptInvite {
        background-color: #005DA6;
        color: #FFFFFF;
    }

    > #rejectInvite {
        background-color: #FFFFFF;
        color: #005DA6;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #005DA6 !important;
    font-family: "Arial", sans-serif;

    &:focus {
        outline: 1px solid #005DA6;
    }
}

.uiButton {
    background-color: #005DA6 !important;
}

.embeddedServiceLiveAgentStateWaitingHeader {
    .waitingGreeting, .waitingMessage {
        color: #005DA6;
    }
}


.embeddedServiceSidebarHeader {
    button {
        width: auto;
    }
    .content {
        margin-top: auto;
        border-top-width: 0;
    }
}

.chat-hide {
    display: none;
}

.optionName{
    color:white;
}
.message{
    color:white;            
}
.embeddedServiceIcon{
    background-color:#005DA6;
}
#chatButtonAssistiveText {
    display: block !important;
    color: white;
}
.helpButton{    
    bottom: 60px !important;
    right: 1% !important;
}

.sidebarHeader[embeddedService-chatHeader_chatHeader] {
    height: 50px !important;
    max-height: 50px !important;
}
.embeddedServiceSidebar .sidebarBody {
    height: ~"calc(100% - 50px)" !important;
}

#ClientID__c {
    display: none;
}

label[for="ClientID__c"] {
    display: none;
}

#Database__c {
    display: none;
}

label[for="Database__c"] {
    display: none;
}