@media (max-width: 767.98px) {
    body.modal-open > .hideBackground {
        display: none;
        padding: 0;
        overflow: scroll;
        position: fixed;
    }

    .e-billing .modal {
        position: relative;
    }
}

.e-billing .modal-content {
    max-width: 455px;
    margin: 0 auto;
    text-align: center;
    padding: 50px;
    border: 0;
}

.e-billing .email-discovery { 
    padding: 50px 0px 50px 0px !important;
}

.e-billing .paperless-modal-content {
    max-width: 555px !important;
}

.e-billing .close {
    position: absolute;
    right: 16px;
    top: 8px;
    float: none;
    font-weight: 400;
    color: #999999;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

    .e-billing .close:hover {
        color: #555555;
    }

.e-billing .modal-body {
    padding: 0 !important;
}

.e-billing h1.header {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #005BBB;
    margin: 0;
    letter-spacing: 0;
    padding-bottom: 25px;
}

.e-billing h2.sub-head {
    color: #3D3D3D;
    font-size: 15px;
    font-weight: 700;
    line-height: 26px;
    padding: 0;
    margin: 25px 0 0 0;
}

.e-billing .paperless-communication-text {
    max-width: inherit;
}

.e-billing .email-discovery .paperless-communication-text {
    margin: 0px 50px 0px 50px;
}

.e-billing p {
    font-family: "Roboto", sans-serif;
    color: #3D3D3D;
    font-size: 13px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 0;
    max-width: 315px;
    margin: 0 auto;
}

.e-billing h3 {
    color: #3D3D3D;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin: 25px 0 0;
}

.e-billing .basic-button {
    display: block;
    border-radius: 4px;
    width: auto;
    min-width: 144px;
    margin: 25px auto 0;
    padding: 12px 0;
    text-align: center;
    background: #8DC63F;
    border: 1px solid #8DC63F;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-decoration: none;
    cursor: pointer;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.e-billing .email-discovery .basic-button { 
    color: #fff !important;
}

    .e-billing .basic-button:hover {
        background: #8DD32D;
        border: 1px solid #8DD32D;
    }

    .e-billing .basic-button.not-active {
        background: #cccccc;
        border: 1px solid #cccccc;
    }



/* Button Animation */
.basic-flow .btn2 {
    background: #8dc63f;
    width: 144px;
    height: 44px;
    font-size: 12px;
    font-weight: 500;
    position: relative;
    color: #000;
    cursor: pointer;
    text-align: center;
    transition: all 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #8DC63F;
    text-decoration: none;
}

.email-discovery .btn2 {
    background: #8DC63F;
    height: 50px;
    font-size: 18px;
    font-weight: 600;
    position: relative;
    color: #fff;
    cursor: pointer;
    text-align: center;
    transition: all 400ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #8DC63F;
    text-decoration: none;
}

.adjustable-btn {
    width: 60%;
}

.e-billing .btn2 {
    display: block;
    margin: 35px auto 0;
    margin-bottom: 0;
}

.e-billing .email-discovery .btn2 { 
    color: black;
}

.e-billing .basic-flow .btn2.not-active {
    color: #fff;
    background: #545454;
    border: 1px solid #545454;
}

    .e-billing .email-discovery .btn2.not-active {
        background: #78848F;
        border: 1px solid #cccccc;
        color: white;
    }

.btn2:focus {
    outline: none;
}

.basic-flow .btn2:hover { 
    color: #000;
}

.btn2:hover {
    background: #8DD32D;
    border: 1px solid #8DD32D;
}

/* Button Complete */
.btn2-complete {
    padding: 10px;
    width: 90px;
    pointer-events: none;
}

.basic-flow .btn2-complete { 
    color: #000;
}

.email-discovery .btn2-complete {
    color: #fff;
}

    .btn2-complete:after {
        content: "Thanks!";
        height: 100%;
        font-weight: 500;
        padding-left: 3px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #8DC63F;
    }

    .basic-flow .btn2-complete:after { 
        color: #000;
    }

    .email-discovery .btn2-complete:after {
        color: #fff;
    }

/* Animation */
@keyframes fill {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

.sub-close {
    display: block;
    text-align: center;
    width: auto;
    font-size: 11px;
    font-weight: bold;
    color: #005DA7;
    background: none;
    padding: 0;
    border: none;
    margin: 15px auto 0;
    text-decoration: none;
    cursor: pointer;
}

.email-discovery .sub-close { 
    font-size: 14px !important;
}

.e-billing a {
    text-decoration: underline;
    -moz-transition-duration: .3s;
    -o-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}

.e-billing .basic-flow a { 
    color: #0055bb;
}

.e-billing .email-discovery a {
    color: #8DC63F;
}

    .e-billing a:hover, .e-billing a:active {
        color: #8DD32D;
        text-decoration: none;
    }

    .e-billing a:focus {
        outline: none;
        outline-offset: 0;
    }

.e-billing #start-paperless img {
    width: 120px;
    margin: 0 auto 25px;
}

.e-billing #restart-paperless img {
    width: 120px;
    margin: 0 auto 25px;
}

.e-billing #confirm-cell-number img {
    width: 60px;
    margin: 0 auto;
}

.e-billing .sub-text {
    font-size: 12px;
    line-height: 24px;
    color: #999999;
    max-width: 300px;
    margin: 15px auto 0;
}

.verify img {
    width: 66px;
    margin: 0 auto;
    padding-bottom: 25px;
}

.email-discovery .paperless-box {
    margin: 20px 50px 0px 50px;
}

.paperless-phone-number {
    margin: 0px;
    position: relative;
    display: flex;
    width: 100%;
}

.email-discovery .paperless-phone-number {
    justify-content: center;
    background-color: #005BBB;
}

.email-discovery .contact-info-box {
    background-color: #005BBB;
    padding: 10px 0px 20px 0px;
}

.email-discovery .contact-info-box input {
        padding: 10px;
        font-size: 16px;
        font-weight: 600;
        border-radius: 10px;
        border: none;
    }

.email-discovery .contact-info-box .center-aligned-input {
        text-align: center;
    }

.email-discovery .contact-info-box .left-aligned-input {
        text-align: left;
        padding-left: 5px !important;
    }

.email-discovery .contact-info-box .verified-info input {
        height: 20px;
        width: 20px;
    }

.email-discovery .contact-info-box input[disabled] {
        background-color: gainsboro;
    }

.email-discovery .contact-info-box .verified-info {
        display: flex;
        align-items: center;
    }

    .email-discovery .contact-info-box .verified-info span {
        color: white;
        margin-left: 10px;
    }

    .email-discovery .contact-info-box .margin-row {
        margin-top: 4px;
    }

    .email-discovery .contact-info-box .no-padding {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .email-discovery .input-container .row { 
        margin-bottom: 5px;
    }

.phone-number {
    position: relative;
    display: flex;
    width: 100%;
    margin: 20px 0 35px 0;
}

.phone-col {
    position: relative;
}

    .phone-col:first-child {
        margin-right: 30px;
    }

    .phone-col:nth-child(2) {
        margin-right: 30px;
    }

.slash:before {
    content: "-";
    position: absolute;
    right: -22px;
    bottom: 3px;
    font-family: 'Open Sans', sans-serif;
    color: #dddddd;
}

.phone-col input {
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    padding: 0 0 5px 5px;
    border: 0;
    border-bottom: 1px solid #DDDDDD;
    outline: 0;
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    color: #005DA7;
}

.phone-col ::placeholder {
    color: #aaaaaa;
    opacity: 1;
}

.phone-col :-ms-input-placeholder {
    color: #aaaaaa;
}

.phone-col ::-ms-input-placeholder {
    color: #aaaaaa;
}

.email-col {
    width: 100%;
}

    .email-col input {
        font-family: 'Open Sans', sans-serif;
        width: 100%;
        padding: 0 0 5px 5px;
        border: 0;
        border-bottom: 1px solid #DDDDDD;
        outline: 0;
        font-size: 13px;
        font-weight: bold;
        text-decoration: none;
        color: #005DA7;
    }

.phone-col ::placeholder {
    color: #aaaaaa;
    opacity: 1;
}

.phone-col :-ms-input-placeholder {
    color: #aaaaaa;
}

.phone-col ::-ms-input-placeholder {
    color: #aaaaaa;
}

/* Radio Buttons */
.foundations {
    display: inline-block;
    padding-top: 15px;
}

    .foundations ul {
        display: inline-block;
        margin: 0;
        padding: 0;
    }

        .foundations ul li {
            color: #272B2F;
            display: inline-block;
            position: relative;
            float: left;
            vertical-align: middle;
            width: 100%;
            padding: 25px 0;
            border-bottom: 1px solid #e5e5e5;
        }

            .foundations ul li:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            .foundations ul li input[type=radio] {
                position: absolute;
                visibility: hidden;
                left: 0px;
                top: 31px;
            }

            .foundations ul li label {
                position: relative;
                float: left;
                display: inline-block;
                width: 100%;
                font-family: 'Roboto', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 27px;
                vertical-align: middle;
                padding: 0 0 0 25px;
                margin: 0;
                cursor: pointer;
                text-align: left;
                z-index: 99;
            }

            .foundations ul li .check {
                position: absolute;
                border: 1px solid #D9DDE2;
                border-radius: 100%;
                height: 14px;
                width: 14px;
                top: 31px;
                left: 0;
                z-index: 5;
            }

                .foundations ul li .check::before {
                    display: block;
                    position: absolute;
                    content: '';
                    border-radius: 100%;
                    height: 6px;
                    width: 6px;
                    top: 3px;
                    left: 3px;
                    margin: auto;
                }

    .foundations input[type=radio]:checked ~ .check {
        border: 1px solid #005DA7;
    }

        .foundations input[type=radio]:checked ~ .check::before {
            background: #005DA7;
        }

    .foundations i {
        color: #c5c5c5;
        font-weight: 500;
        font-size: 15px;
        line-height: 27px;
        vertical-align: middle;
        margin-left: 10px;
        -moz-transition-duration: .3s;
        -o-transition-duration: .3s;
        -webkit-transition-duration: .3s;
        transition-duration: .3s;
    }

        .foundations i:hover {
            color: #8DC63F;
        }

.f-progress {
    float: left;
    display: block;
    vertical-align: middle;
    padding: 5px 0 0 25px;
}

h4.amount {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    color: #8DC63F;
    margin-bottom: 2px;
    text-align: center;
}

span.amount-s-t {
    padding-right: 5px;
    font-weight: 400;
}

.basic-flow span.amount-s-t {
    color: #444442;
}

.email-discovery span.amount-s-t{
    color: #999999;
}

.finished img {
    width: 60px;
    padding-bottom: 25px;
}

.basic-flow .finished img {
    margin: 0 auto;
}

.email-discovery .finished img {
    margin-right: 10px;
}

.finished p {
    margin-bottom: 20px;
    font-size: 18px;
    max-width: 450px;
}

.finished .bold-text {
    font-weight: 600;
}

.confirmation-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

#confirmation-box {
    margin-left: 10px;
    margin-right: 10px;
}

    #confirmation-box .confirmation-header h1.header {
        margin: 0 0;
    }

/*=================================================================================================================================================================================================================================================================================================================================================================

    Media Query

=================================================================================================================================================================================================================================================================================================================================================================*/

@media all and (max-width: 475px) {
    .e-billing .basic-flow { 
        padding: 25px;
    }

    .e-billing .paperless-box-list li {
        display: unset;
    }

    .e-billing .paperless-communication-text {
        margin: 0px 10px 0px 10px;
    }

    #btnEndConfirmEmailAddress {
        width: 95%;
        font-size: 16px;
    }

    .e-billing h1.header {
        max-width: 275px;
        margin: 0 auto;
    }

    .e-billing h3 {
        font-size: 14px;
        line-height: 28px;
    }

    .foundations ul li label {
        font-size: 14px;
        line-height: 25px;
        padding: 0 0 0 15px;
    }

    .foundations ul li .check {
        top: 32px;
        height: 10px;
        width: 10px;
    }

    .foundations ul li input[type=radio] {
        top: 30px;
    }

    .foundations input[type=radio]:checked ~ .check {
        border: 1px solid #005DA7;
    }

    .foundations ul li .check::before {
        height: 4px;
        width: 4px;
        top: 2px;
        left: 2px;
    }

    h4.amount {
        font-size: 13px;
        line-height: 13px;
    }

    .f-progress {
        padding: 0 0 0 15px;
    }

    .foundations i {
        font-size: 13px;
        line-height: 13px;
    }
}
/* 475px media query end */

.e-billing .gray-out {
    position: absolute;
    z-index: 1100;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .5;
    margin: -50px;
}

.sk-circle {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

    .sk-circle .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

        .sk-circle .sk-child:before {
            content: '';
            display: block;
            margin: 0 auto;
            width: 15%;
            height: 15%;
            background-color: white;
            border-radius: 100%;
            -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        }

    .sk-circle .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg);
    }

    .sk-circle .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg);
    }

    .sk-circle .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
    }

    .sk-circle .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg);
    }

    .sk-circle .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg);
    }

    .sk-circle .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .sk-circle .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg);
    }

    .sk-circle .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg);
    }

    .sk-circle .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    .sk-circle .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg);
    }

    .sk-circle .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg);
    }

    .sk-circle .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .sk-circle .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
    }

    .sk-circle .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .sk-circle .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    .sk-circle .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
    }

    .sk-circle .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
    }

    .sk-circle .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
    }

    .sk-circle .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
    }

    .sk-circle .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
    }

    .sk-circle .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
    }

    .sk-circle .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
    }

@-webkit-keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.submitting::after {
    vertical-align: middle;
    content: "";
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: -15px -20px -14px 10px;
    background: transparent;
    box-sizing: border-box;
    border-top: 4px solid white;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 4px solid transparent;
    border-radius: 100%;
    animation-delay: 0.5s;
    animation: spin 0.6s ease-in-out infinite;
    transition: all ease 0.5s;
}

@keyframes spin {
    100% {
        transform: rotate(360deg)
    }
}
