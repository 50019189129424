﻿#header1 {
    font-size: 26px;
    color: #005BBB;
    text-align: center;
    margin-bottom: 10px;
}

#header2 {
    font-size: 17px;
    text-align: center;
}

.wrap {
    margin: 0 auto;
}

.left_col {
    font-weight: bold;
    float: left;
    padding-left: 30px;
}

.right_col {font-weight: bold;
    float: right;
    border-left: 1px solid black;
    padding-left: 20px;
}

.right_col_noborder {
    font-weight: bold;
    float: right;
    padding-left: 20px;
}

fieldset {
    overflow: hidden
}

.addressvalidationdiv {
    float: left;
    clear: none;

}

.addressvalidationlabel {
    float: left;
    clear: none;
    display: block !important;
    padding: 0px 1em 0px 8px;
    width: 249px !important;
}

.addressvalidationradiobutton {
    float: left;
    clear: none;
    margin: 2px 0 0 2px;
}

.modal-media-content.no-border {
    border: none;
}

.modal-content.no-border.addressvalidationmodal {
    width: 600px;
    height: 375px;
}

.addressvalidation-btn {
    width: 200px;
    margin-top: 0px;
}

@media only screen and (max-width: 580px) {
    .modal-content.no-border.addressvalidationmodal {
        width: 100%;
        height: 100%;
    }
    .wrap {
        width: 90%;
        margin: 0 auto;
    }

    .left_col {
        font-weight: bold;
        float: unset;
        padding-left: 0px;
    }

    .right_col {
        font-weight: bold;
        float: unset;
        border-left: 0px solid black;
        padding-left: 0px;
    }

    .right_col_noborder {
        font-weight: bold;
        float: unset;
        padding-left: 0px;
        padding-top: 20px;
    }
    
    .addressvalidation-btn {
        margin-top: 5px;
    }
}